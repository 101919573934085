.login-container {
  height: 100vh;
  width: calc(100vw - 2rem);
  background-color: #263c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.login-container__login-modal {
  width: 100%;
  max-width: 600px;
  height: 60%;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}
.login-container .login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 300px;
}
.login-container .login-form .input-format {
  padding: 10px 20px;
  font-weight: 600;
  color: black;
  font-size: 16px;
  width: 60%;
  border: none;
  border-bottom: 1.5px solid #b8bcc0;
  margin: 0px auto 20px auto;
}
.login-container .login-form .input-format:focus {
  outline: none;
}
.login-container .login-form .input-format::placeholder {
  color: #74818d;
}
.login-container .login-modal__sign-up {
  font-family: "Roboto";
  font-weight: 600;
  color: black;
  font-size: 20px;
  text-align: center;
  width: 60%;
  line-height: 1.625;
  outline: none;
  padding: 80px 0;
  margin: 0px auto 0 auto;
}
.login-container .login-modal__fields-to-fill-warning {
  text-align: center;
  color: #cc3d3d;
  width: 50%;
  margin: 0px auto 0 auto;
}
.login-container .login-modal__login-buttons {
  width: 60%;
  margin: 20px auto 50px auto;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  height: 30px;
  line-height: 1em;
  background-color: #263c5c;
  border-width: 1px;
  outline: none;
  padding: 0px 16px;
  border-color: transparent;
  border-radius: 60px;
  transition: all 250ms ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px 2px 2px;
}
.login-container .login-modal__login-buttons:hover {
  background-color: #1b2c44;
}
@media screen and (max-width: 576px) {
  .login-container .login-modal__sign-up {
    padding: 40px 0;
  }
}
