.param-container {
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.param-container__top-section {
  display: flex;
  flex-wrap: wrap;
  padding-left: 3px;
}
.param-container .top-section__name-input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #74818d;
  font-weight: 500;
  margin: 0 5px 0 5px;
  padding: 0 0 0 0;
  width: 35%;
  border: none transparent;
  outline: none;
  border-bottom: black 1rem;
  line-height: 1.525;
}
.param-container .top-section__name-input:focus {
  border-bottom: blue solid 2px;
}
.param-container .top-section__name-input:disabled {
  background-color: transparent;
}
.param-container .top-section__type-select {
  width: 20%;
  min-width: 55px;
  max-width: 80px;
  margin-right: 30px;
}
.param-container .top-section__type-select .type-select__value-container {
  color: black;
  font-size: 85%;
  font-family: "Source Code Pro", Menlo, monospace;
  background-color: #f5f7f9;
  display: inline-block;
  line-height: 1.2;
  margin-top: 3px;
  border-radius: 3px;
  padding: 2px 6px;
}
.param-container .top-section__type-select .type-select__control {
  border: none;
  background-color: #f5f7f9;
  min-height: 28px;
}
.param-container .top-section__type-select .type-select__control--is-focused,
.param-container .top-section__type-select .type-select__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .top-section__type-select .type-select__option {
  text-decoration: none;
  font-size: 85%;
  font-weight: 600;
  padding: 5px;
}
.param-container .top-section__type-select .type-select__option--is-focused {
  color: #3884ff;
}
.param-container .top-section__type-select .type-select__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .top-section__type-select .type-select__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.param-container .top-section__type-select .type-select__indicator {
  display: none;
}
.param-container .top-section__type-select .type-select__indicator-separator {
  display: none;
}
.param-container .top-section__type-select .type-select__menu {
  margin-top: 0px;
  outline: none;
}
.param-container .top-section__describe-input {
  font-family: "Roboto";
  font-weight: 500;
  color: #74818d;
  font-size: 16px;
  width: 45%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 0 10px 0 0;
  margin: 0 0 0 5px;
}
.param-container .top-section__describe-input:focus {
  border: none;
}
.param-container .top-section__describe-input:disabled {
  background-color: transparent;
}
.param-container .top-section__button-to-delete {
  display: none;
  position: absolute;
  right: 14%;
  color: #74818d;
  padding-top: 0px;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}
.param-container .top-section__button-to-delete:hover {
  color: black;
}
.param-container .top-section__button-to-delete:focus {
  outline: none;
}
.param-container__bottom-section {
  margin-top: -5px;
  display: flex;
  padding-left: 3px;
}
.param-container .bottom-section__option-select {
  margin-left: 5px;
  width: 30%;
  min-width: 75px;
  max-width: 75px;
  background-color: transparent;
}
.param-container .bottom-section__option-select .get-red-required-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .bottom-section__option-select .get-red-required-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .bottom-section__option-select .get-red-required-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .bottom-section__option-select .get-red-required-color__single-value {
  color: red;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .bottom-section__option-select .get-red-required-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .bottom-section__option-select .get-red-required-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .bottom-section__option-select .get-red-required-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .bottom-section__option-select .get-red-required-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .bottom-section__option-select .get-red-required-color__option--is-focused {
  color: #3884ff;
}
.param-container .bottom-section__option-select .get-red-required-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .bottom-section__option-select .get-red-required-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .bottom-section__option-select .get-red-required-color__indicator-separator {
  display: none;
}
.param-container .bottom-section__option-select .get-red-required-color__menu {
  margin: 0px;
  outline: none;
}
.param-container .bottom-section__option-select .get-grey-optional-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .bottom-section__option-select .get-grey-optional-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .bottom-section__option-select .get-grey-optional-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .bottom-section__option-select .get-grey-optional-color__single-value {
  color: #74818d;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .bottom-section__option-select .get-grey-optional-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .bottom-section__option-select .get-grey-optional-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .bottom-section__option-select .get-grey-optional-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .bottom-section__option-select .get-grey-optional-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .bottom-section__option-select .get-grey-optional-color__option--is-focused {
  color: #3884ff;
}
.param-container .bottom-section__option-select .get-grey-optional-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .bottom-section__option-select .get-grey-optional-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .bottom-section__option-select .get-grey-optional-color__indicator-separator {
  display: none;
}
.param-container .bottom-section__option-select .get-grey-optional-color__menu {
  margin: 0px;
  outline: none;
}
.param-container:hover .top-section__button-to-delete {
  display: block;
}
